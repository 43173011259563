import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import  Layout  from 'src/layouts/Layout';
import Section from 'src/components/shared/wraps/Section/Section';
import BigTitle from 'src/components/shared/text/BigTitle';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import SEO from 'src/components/seo';
import { Wrapper } from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import Arrow from 'src/components/shared/navigations/Pagination/Arrow';
import {
  FadeIn,
  FadeInImmediately,
} from 'src/components/shared/Animations/Animations';
import {
  RotatingLetter,
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import {useI18next} from "gatsby-plugin-react-i18next";
import useLng from "src/hooks/useLng";

const StyledBigTitle = styled(BigTitle)`
  text-align: center !important;
  width: 100% !important;
  margin-bottom: 7rem !important;
`;

const StyledImage = styled(Image)`
  height: ${({ secondary }) =>
    secondary ? 'clamp(28rem, 41.3vw, 59.5rem)' : 'clamp(28rem, 33vw, 46rem)'};
  max-width: 100% !important;
  max-height: unset !important;

  img {
    width: 100%;
  }

  picture img {
    transform-origin: center;
    transition: transform 0.5s ease, opacity 0.5s ease 0s !important;
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: 0.1;
    transition: opacity 0.5s ease 0.2s;
  }

  :hover {
    picture img {
      transform: scale(1.1);
    }

    ::after {
      opacity: 0.4;
    }
  }
`;

const StyledGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: clamp(0.5rem, 2vw, 3rem);
  margin-top: clamp(0.5rem, 2vw, 3rem);

  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;

const StyledSection = styled(Section)`
  padding-left: 0;
  padding-right: 0;
`;
const RightLinkBorder = styled.span`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.main};
`;
const StyledButton = styled(FadeLink)`
  background-color: transparent;
  border: none;
  padding: 1rem 2rem;
  margin: clamp(6rem, 10vw, 14rem) auto;
  display: block;
  width: 20rem;
  font-size: 4rem;
  cursor: pointer;
  text-align: center;
  opacity: ${({ disabled }) => disabled && 0.5};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const StyledProjectWrapper = styled(FadeLink)`
  position: relative;
  display: block;
`;

const StyledArrowWrapper = styled(FadeLink)`
  position: absolute;
  left: ${({ left }) => left && 0};
  right: ${({ right }) => right && 0};
  top: ${({ top }) => top && 0};
  bottom: ${({ bottom }) => bottom && 0};
  width: 18rem;
  height: 7rem;
  z-index: 1;

  &:hover ~ ${StyledImage} {
    picture img {
      transform: scale(1.1);
    }

    &::after {
      opacity: 0.4;
    }
  }

  @media screen and (max-width: 575px) {
    width: 15rem;
    height: 5rem;
  }
`;

const StyledProjectTitle = styled.h2`
  position: absolute;
  right: 20rem;
  bottom: 2.2rem;
  // transform: translate(-50%, -50%);
  font-size: ${({ small }) => (small ? '1rem' : '2rem')};
  color: ${({ theme }) => theme.colors.white};
  opacity: 0;
  transition: opacity 0.3s ease 0.2s;
  pointer-events: none;
  text-align: center;
  max-width: 95%;
  z-index: 1;

  ${StyledProjectWrapper}:hover & {
    opacity: 1;
  }

  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 575px) {
    white-space: normal;
  }
`;

const Projects = ({
  data,
  location: { pathname: path },
  transitionStatus,
  entry,
  exit,
}) => {
  const [projectsNumberToShow, setProjectsNumberToShow] = useState(8);
  const [showButton, setShowButton] = useState(true);
  const latestProject = data.directusMainProject.main_project_id
  const [...projects] = data.allDirectusProjekty.nodes.filter(el => el.id !== latestProject.id);
  projects.sort((a, b) => a.waga > b.waga);
  console.log(projects)
  const onMoreClick = () => {
    if (projects.length === projectsNumberToShow) {
      return;
    }
    if (projects.length - projectsNumberToShow > 4) {
      setProjectsNumberToShow(projectsNumberToShow + 4);
    } else {
      setShowButton(false);
      setProjectsNumberToShow(
        projectsNumberToShow + (projects.length - projectsNumberToShow),
      );
    }
  };
  const {t} = useLng()
  const {language} = useI18next()

  return (
    <Layout
      path={path}
      id="projekty"
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title={t("projekty")} />
      <StyledSection first nonGrid site nonFlex>
        <StyledBigTitle h1>
          {language === "pl" ? <NoWrap>
            Projekt<RotatingLetter delay={0.4}>y</RotatingLetter>
          </NoWrap>: <NoWrap>
            Project<RotatingLetter delay={0.4}>s</RotatingLetter>
          </NoWrap>}
        </StyledBigTitle>
        <Wrapper nonGrid>
          <StyledProjectWrapper to={`/projekty/${latestProject.slug}`}>
            <StyledArrowWrapper
              right
              bottom
              to={`/projekty/${latestProject.slug}`}
            >
              <Arrow right />
            </StyledArrowWrapper>
            <StyledProjectTitle>{latestProject.title}</StyledProjectTitle>
            <StyledImage
              fluid={latestProject.preview.localFile.childImageSharp.fluid}
              alt={latestProject.title}
            />
          </StyledProjectWrapper>
          <StyledGridWrapper>
            {projects.map((project, index) => {
              if (index >= projectsNumberToShow) return;
              if (index < 2) {
                return (
                  <StyledProjectWrapper
                    key={project.id}
                    to={`/projekty/${project.slug}`}
                  >
                    <StyledArrowWrapper
                      right
                      bottom
                      to={`/projekty/${project.slug}`}
                    >
                      <Arrow right />
                    </StyledArrowWrapper>
                    <StyledProjectTitle>{project.title}</StyledProjectTitle>
                    <StyledImage
                      secondary
                      fluid={project.preview?.localFile?.childImageSharp?.fluid}
                      alt={project.title}
                    />
                  </StyledProjectWrapper>
                );
              }
              if (index < 4) {
                return (
                  <StyledProjectWrapper
                    key={project.id}
                    to={`/projekty/${project.slug}`}
                  >
                    <FadeIn up animationToProp={{ duration: 2 }}>
                      <StyledArrowWrapper
                        right
                        bottom
                        to={`/projekty/${project.slug}`}
                      >
                        <Arrow right />
                      </StyledArrowWrapper>
                      <StyledProjectTitle>{project.title}</StyledProjectTitle>
                      <StyledImage
                        secondary
                        fluid={project.preview.localFile.childImageSharp.fluid}
                        alt={project.title}
                      />
                    </FadeIn>
                  </StyledProjectWrapper>
                );
              }
              return (
                <StyledProjectWrapper
                  key={project.id}
                  to={`/projekty/${project.slug}`}
                >
                  <FadeInImmediately>
                    <StyledArrowWrapper
                      right
                      bottom
                      to={`/projekty/${project.slug}`}
                    >
                      <Arrow right />
                    </StyledArrowWrapper>
                    <StyledProjectTitle>{project.title}</StyledProjectTitle>
                    <StyledImage
                      secondary
                      fluid={project.preview?.localFile?.childImageSharp?.fluid}
                      alt={project.title}
                    />
                  </FadeInImmediately>
                </StyledProjectWrapper>
              );
            })}
          </StyledGridWrapper>
        </Wrapper>
      </StyledSection>
      <div
        style={
          showButton
            ? { opacity: 1 }
            : { opacity: 0, pointerEvents: 'none', height: 0 }
        }
      >
        <StyledButton
          duration={'1s'}
          borderWidth={'3px'}
          stylish
          noLink
          onClick={onMoreClick}
        >
          {t("wiecej")}
        </StyledButton>
      </div>
    </Layout>
  );
};

export const galleryQuery = graphql`
query ProjektyMakaduQuery {
  directusMainProject {
    main_project_id{
      id
      title
      slug
      preview {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }}
  }
  allDirectusProjekty(
    sort: {fields: [waga], order: [DESC, DESC]}
  ) {
    nodes {
      id
      title
      slug
      waga
      preview {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
}

`;

export default Projects;
